<template>
  <CardGray header="Ingredients Table" v-model="search" placeholder="search (Type, Supplier)">
    <Table class="table-hover" v-if="Object.values(ingredients).length">
      <template v-slot:headRow>
        <TH></TH>
        <THSort :sort="state.category" @click="sortNum('category')">Type</THSort>
        <THSort :sort="state.name" @click="sortStr('name')"> Name</THSort>
        <THSort :sort="state.supplierName" @click="sortStr('supplierName')">Supplier</THSort>
        <THSort :sort="state.total" @click="sortNum('total')"> Quantity </THSort>
        <THSort :sort="state.ebc" @click="sortNum('ebc')"> ebc</THSort>
        <THSort :sort="state.aa" @click="sortNum('aa')"> AA</THSort>
        <THSort :sort="state.attenuation" @click="sortNum('attenuation')"> Attenuation</THSort>
      </template>
      <template v-slot:body>
        <TR v-for="(item, index) in searched" :key="index">
          <TD>
            <ButtonGroup>
              <Button @click="openIngredient(item.id)">
                <BIconPencilSquare />
              </Button>
              <!-- <ButtonLink name="IngredientDetail" :params="item.id">
                <BIconEye />
              </ButtonLink> -->
              <Button @click="openIngredientInventory(item.id)">
                <IconTruck />
              </Button>
              <Button @click="openDeleteIngredient(item.id)">
                <BIconTrash />
              </Button>
            </ButtonGroup>
          </TD>
          <TD> {{ item.categoryName }}</TD>
          <TD> {{ item.name }}</TD>
          <TD>{{ item.supplierName }}</TD>
          <TD>{{ item.total }} {{ item.unitText }}</TD>
          <TD>{{ item.ebc ? item.ebc : "-" }} </TD>
          <TD>{{ item.aa ? item.aa + " %" : "-" }} </TD>
          <TD>{{ item.attenuation ? item.attenuation + " %" : "-" }} </TD>
        </TR>
      </template>
    </Table>
  </CardGray>
</template>

<script>
import comp from "../../components/base/"
import Fuse from "fuse.js"
import { mapActions, mapGetters } from "vuex"
import icons from "../../assets/icons"
import CardGray from "../base/CardGray.vue"

export default {
  name: "IngredientsTable",
  components: {
    ...comp,
    ...icons,
    CardGray,
  },
  setup() {
    return {}
  },
  data() {
    return {
      state: {},
      search: "",
    }
  },
  computed: {
    ...mapGetters(["popIngredients"]),
    ingredients() {
      return Object.values(this.popIngredients)
    },
    fuse() {
      return new Fuse(this.ingredients, {
        keys: ["name", "supplierName", "categoryName"],
      })
    },
    searched() {
      return this.search ? this.fuse.search(this.search).map(item => item.item) : this.ingredients
    },
  },
  methods: {
    ...mapActions(["openIngredient", "openIngredientInventory"]),
    ...mapActions(["openDeleteIngredient"]),

    sortNum(key) {
      if (this.state[key]) this.searched.sort((a, b) => a[key] - b[key])
      else this.searched.sort((a, b) => b[key] - a[key])
      this.state[key] = !this.state[key]
    },
    sortStr(key) {
      this.searched.sort((a, b) => {
        let val = String(a[key]).localeCompare(b[key])
        return this.state[key] ? val : val * -1
      })
      this.state[key] = !this.state[key]
    },
  },
}
</script>
