<template>
  <Container>
    <Header>
      Ingredients
      <template #right>
        <BadgeBtn @click="openIngredient()">
          + Ingredient
        </BadgeBtn>
      </template>
    </Header>
    <IngredientsTable class="my-5" />
  </Container>
</template>

<script>
import { mapActions } from "vuex"
import comp from "../components/base"
import IngredientsTable from "../components/ingredients/IngredientsTable.vue"

export default {
  name: "Ingredients",
  components: {
    ...comp,
    IngredientsTable,
  },
  methods: {
    ...mapActions(["openIngredient"]),
  },
}
</script>
, IngredientsTable
